import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { mainUrl, updateMessageUrl, updateOrderUrl } from "../costants";
import { deleteOrder, getAllOrders } from "../../../api/services/orderService";
import CustomLoading from "../../custom/CustomLoading";
import CustomIconButton from "../../custom/CustomIconButton";
import { DeleteForeverOutlined, ModeEdit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DeleteOrderRequest } from "../../../api/requests/orderService";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomInput from "../../custom/CustomInput";
import { getRawMessagesUrl } from "../../../api/services/url";
import { getAllRawMessages } from "../../../api/services/messageService";

type RawMessagesProps = {
    setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
    title: string;
    link: string | ((id: string) => string);
};

const RawMessages: React.FC<RawMessagesProps> = ({
    setBreadcrumb,
    title,
    link,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setBreadcrumb([
            <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
                {t("home")}
            </Link>,
            <Typography color="#ffffff" key="2">
                {t(title)}
            </Typography>,
        ]);
    }, []);

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [reload, setReload] = useState<boolean>(true);
    const [orders, setOrders] = useState<any[]>([]);
    const [messages, setMessages] = useState<any[]>([]);
    const [inputValueWork, setInputValueWork] = useState<string>(
        localStorage.getItem("SCTOrderIdWork") || ""
    );
    const [inputValueAll, setInputValueAll] = useState<string>(
        localStorage.getItem("SCTOrderIdAll") || ""
    );
    const [newOrders, setNewOrders] = useState<any[]>([]);
    const [newOrdersAll, setNewOrdersAll] = useState<any[]>([]);

    useEffect(() => {
        getAllRawMessages().then((res: any) => {
            if (res && !res.error) {
                //add serial row_id key for each message
                res.data.forEach((element: any, index: number) => {
                    element.row_id = index + 1;
                });
                setMessages(res.data);
            }
        })
        setLoading(false);
    }, [reload]);

    //Method to get orders based on input in custom input field
    const getNewOrdersAll = (order_id: string) => {
        setLoading(true);
        let newOrders = orders.filter((order: any) =>
            String(order.order_id)
                .toLocaleLowerCase()
                .includes(String(order_id).toLocaleLowerCase())
        );
        setNewOrdersAll(newOrders);
        setLoading(false);
    };

    if (loading) {
        return <CustomLoading />;
    }

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ padding: "100px 32px" }}
        >
            <Grid item xs={12}>
                <CustomTitle title={t(title)} goBack={() => navigate(mainUrl)} />
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label={`${t("toEdit")}`} />
                        <Tab label={`${t("all")}`} />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <div role="tabpanel" hidden={tabValue !== 0}>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <CustomTable
                                    columns={[
                                        {
                                            headerName: `${t("message")}`,
                                            field: "message",
                                            description: `${t("message")}`,
                                            flex: 1,
                                            filterable: false,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    {params.row.row_id}
                                                </>
                                            ),
                                        },
                                        {
                                            headerName: `${t("created")}`,
                                            field: "created_at",
                                            description: `${t("created")}`,
                                            flex: 1,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    {params.row.created_at.split("T")[0]}{" "}
                                                    {new Date(params.row.created_at).toLocaleTimeString('it-IT')}
                                                </>
                                            ),
                                        },
                                        {
                                            headerName: "",
                                            field: "",
                                            flex: 1,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    <CustomIconButton
                                                        type="outlined"
                                                        icon={
                                                            <Tooltip title={t("edit")}>
                                                                <ModeEdit />
                                                            </Tooltip>
                                                        }
                                                        onClick={() =>
                                                            navigate(updateMessageUrl(params.row.id))
                                                        }
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                    rows={messages.filter(
                                        (message: any) => message.processed_at === "0001-01-01T00:00:00Z"
                                    )}
                                    stickyHeader
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div role="tabpanel" hidden={tabValue !== 1}>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <CustomTable
                                    columns={[
                                        {
                                            headerName: `${t("message")}`,
                                            field: "message",
                                            description: `${t("message")}`,
                                            flex: 1,
                                            filterable: false,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    {params.row.row_id}
                                                </>
                                            ),
                                        },
                                        {
                                            headerName: `${t("created")}`,
                                            field: "created_at",
                                            description: `${t("created")}`,
                                            flex: 1,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    {params.row.created_at.split("T")[0]}{" "}
                                                    {new Date(params.row.created_at).toLocaleTimeString('it-IT')}
                                                </>
                                            ),
                                        },
                                        {
                                            headerName: `${t("edited")}`,
                                            field: "edited_at",
                                            description: `${t("edited")}`,
                                            flex: 1,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    {(params.row.processed_at.split("T")[0] === '0001-01-01' ? 'N.A.' : params.row.processed_at.split("T")[0] + " " + new Date(params.row.processed_at).toLocaleTimeString('it-IT'))}
                                                </>
                                            ),
                                        },
                                        {
                                            headerName: "",
                                            field: "",
                                            flex: 1,
                                            renderCell: (params: GridRenderCellParams) => (
                                                <>
                                                    <CustomIconButton
                                                        type="outlined"
                                                        icon={
                                                            <Tooltip title={t("edit")}>
                                                                <ModeEdit />
                                                            </Tooltip>
                                                        }
                                                        onClick={() =>
                                                            navigate(updateMessageUrl(params.row.id))
                                                        }
                                                    />
                                                </>
                                            ),
                                        },
                                    ]}
                                    rows={messages}
                                    stickyHeader
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RawMessages;
