import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import CustomInput from "../../custom/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { mainUrl, rawMessagesUrl } from "../costants";
import CustomLoading from "../../custom/CustomLoading";
import { getRawMessage, resendRawMessage, validateMessage } from "../../../api/services/messageService";
import { useSnackbar } from "notistack";

type UpdateMessageProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  link: string;
};

const UpdateMessage: React.FC<UpdateMessageProps> = ({
  setBreadcrumb,
  title,
  link,
}) => {
  const { id } = useParams(); //user uid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [validateFlag, setValidateFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState({
    id: "",
    payload: "",
    created_at: "",
    received_at: "",
    processed_at: "",
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Link style={{ color: "#ffffff" }} key="2" to={link}>
        {t(title)}
      </Link>,
    ]);
  }, []);

  useEffect(() => {
    getRawMessage(id!).then((res: any) => {
      if (res && !res.error) {
        setMsg(res.data);
        setMsg({ ...msg, payload: (res.data.payload).slice(1, -1) });
      }
      setLoading(false);
    });
  }, []);

  const handleSubmit = () => {
    var payload = msg.payload;
    validateMessage(payload).then((res: any) => {
      if (res && !res.err) {
        console.log(res);
        if (res.valid === 'true') {
          enqueueSnackbar(t("messageValidated"), { variant: "success" });
          setValidateFlag(true);
        } else {
          enqueueSnackbar(t("messageNotValidated"), { variant: "error" });
          setValidateFlag(false);
        }
      }
    });
  };

  const handleSend = () => {
    console.log("send");
    var payload = msg.payload;
    resendRawMessage(id!, payload).then((res: any) => {
      if (res && !res.error) {
        enqueueSnackbar(t("messageSent"), { variant: "success" });
        navigate(-1);
      } else {
        enqueueSnackbar(t("messageNotSent"), { variant: "error" });
      }
    });
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("rawMessage")}
          goBack={() => navigate(rawMessagesUrl)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  multiline={20}
                  label={t("description")}
                  value={msg.payload}
                  handleChange={(e) =>
                    setMsg({ ...msg, payload: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setMsg({
                  id: "",
                  payload: "",
                  created_at: "",
                  received_at: "",
                  processed_at: "",
                })
              }
              confirm
              type="outlined"
            />,
            <CustomButton
              fullWidth
              label={t("validate")}
              onClick={handleSubmit}
              type="contained"
            />,
            <CustomButton
              fullWidth
              label={t("send")}
              onClick={handleSend}
              type="contained"
              disabled={!validateFlag}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateMessage;
