import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { useTranslation } from "react-i18next";
import CustomTable from "../../custom/CustomTable";
import { Grid, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { dashboardManagerUrl, routes } from "../costants";
import { getDashboardManager } from "../../../api/services/dashboardService";
import CustomLoading from "../../custom/CustomLoading";
import CustomText from "../../custom/CustomText";
import CustomTag from "../../custom/CustomTag";
import { darkColor, lightColor } from "../../custom/utils";
import logo from "../../../images/logo_verticale.jpeg";
import { DirectionsBoat } from "@mui/icons-material";

type DashboardManagerProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  title: string;
  setNavbarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  role: string;
  email: string;
};

const DashboardManager: React.FC<DashboardManagerProps> = ({
  setBreadcrumb,
  title,
  setNavbarCollapsed,
  setHeaderCollapsed,
  role,
  email,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const batch_id = searchParams.get("batch_id");
  const batch_status = searchParams.get("batch_status");
  const shift_id = searchParams.get("shift_id");
  const crane_id = searchParams.get("crane_id");
  const crane_descr = searchParams.get("crane_descr");
  const start = searchParams.get("start");
  const end = searchParams.get("end");
  const [title_, setTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [dashboard, setDashboardManager] = useState<any[]>([]);

  useEffect(() => {
    setNavbarCollapsed(true);
    setHeaderCollapsed(true);
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={dashboardManagerUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t(title)}
      </Typography>,
    ]);
    return () => {
      setNavbarCollapsed(false);
      setHeaderCollapsed(false);
    };
  }, []);

  useEffect(() => {
    let newTitle = title_; // Base title

    console.log("batch_status", batch_status);
    //type of batch_status
    console.log(typeof batch_status);

    if (batch_status === "true") {
      console.log("true");
      newTitle += ` ${t("openBatches")}`;
    } else {
      console.log("false");
      newTitle += ` ${t("closedBatches")}`;
    }

    if (batch_id) {
      newTitle += ` ${t("order")} ${batch_id.split("-")[0]}`;
    }

    if (shift_id) {
      newTitle += ` - ${t("shift")} ${shift_id}`;
    }

    if (crane_id) {
      newTitle += ` - ${t("crane")} ${crane_descr}`;
    }

    if (start) {
      newTitle += ` - ${t("startDate")} ${start}`;
    }

    if (end) {
      newTitle += ` - ${t("endDate")} ${end}`;
    }
    setTitle(newTitle);
  }, [batch_id, shift_id, crane_id, batch_status, start, end]);

  useEffect(() => {
    getDashboardManager(
      (batch_id || "").split(" - ")[0] || "",
      batch_status!,
      shift_id || "",
      crane_id || "",
      start || "",
      end || ""
    ).then((res: any) => {
      if (res && res.dashboard) {
        setDashboardManager([...res.dashboard]);
      } else {
        setDashboardManager([]);
      }
      setLoading(false);
    });
    const id = setInterval(() => {
      getDashboardManager(
        (batch_id || "").split(" - ")[0] || "",
        batch_status!,
        shift_id || "",
        crane_id || "",
        start || "",
        end || ""
      ).then((res: any) => {
        if (res && res.dashboard) {
          setDashboardManager([...res.dashboard]);
        } else {
          setDashboardManager([]);
        }
        setLoading(false);
      });
    }, 60000);

    return () => {
      clearInterval(id);
    };
  }, []);

  //method to format date
  const formatDate = (date: string) => {
    var format = "";
    if (date !== "0001-01-01T00:00:00Z") {
      format =
        date.split("T")[0].split("-")[2] +
        "/" +
        date.split("T")[0].split("-")[1] +
        " " +
        date.split("T")[1].split("Z")[0].split(":")[0] +
        ":" +
        date.split("T")[1].split("Z")[0].split(":")[1];
      return format;
    } else {
      return "N.A.";
    }
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "10px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={title_}
          goBack={() =>
            navigate(
              dashboardManagerUrl +
                `?batch_status=${batch_status}&batch_id=${batch_id}&shift_id=${shift_id}&crane_id=${crane_id}&start=${start}&end=${end}`
            )
          }
          action={
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item xs={8}>
                <CustomTag label={role} type="outlined" />
                <CustomText color={darkColor} label={email} type="body1" />
              </Grid>
              <Grid item xs={4}>
                <img
                  style={{ cursor: "pointer", height: "60px" }}
                  alt="logo"
                  src={logo}
                  onClick={() => navigate(routes.mainPage)}
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      {dashboard.length === 0 ? (
        <Grid
          item
          container
          spacing={2}
          xs={12}
          flexDirection="column"
          alignItems="center"
        >
          <Grid item>
            <CustomText
              bold
              type="h4"
              color={lightColor}
              label={t("noVesselsAtTheBerth")}
            />
          </Grid>
          <Grid item>
            <DirectionsBoat
              style={{
                color: lightColor,
                fontSize: "300px",
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomTable
            stickyHeader
            fixedHeight={600}
            columns={[
              {
                headerName: `${t("vessel")}`,
                field: "vessel",
                description: `${t("vessel")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <Link
                    style={{ color: darkColor }}
                    to={`/orders/${
                      params.row.vessel.split(" - ")[0]
                    }/resources/manager?start=${start}&end=${end}&shift=${shift_id}&crane=${crane_id}`}
                  >
                    {params.row.vessel.split(" - ")[1]}
                  </Link>
                ),
              },
              {
                headerName: `${t("totalMovements")}`,
                field: "total_mov",
                description: `${t("totalMovementsDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.total_movements}</>
                ),
              },
              {
                headerName: `${t("movesDone")}`,
                field: "moves_done",
                description: `${t("movesDoneDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.moves_done}</>
                ),
              },
              {
                headerName: `${t("movesToDo")}`,
                field: "moves_todo",
                description: `${t("movesToDoDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.moves_to_do}</>
                ),
              },
              {
                headerName: `${t("percentage")}`,
                field: "percentage",
                description: `${t("percentageDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.percentage + "%"}</>
                ),
              },
              {
                headerName: `${t("vesselFL")}`,
                field: "vessel_fl",
                description: `${t("vesselFLDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{formatDate(params.row.vessel_fl)}</>
                ),
              },
              {
                headerName: `${t("vesselLL")}`,
                field: "vessel_ll",
                description: `${t("vesselLLDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{formatDate(params.row.vessel_ll)}</>
                ),
              },
              {
                headerName: `${t("workingTime")}`,
                field: "working_time",
                description: `${t("workingTimeDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>
                    {params.row.working_time.split(":")[0] +
                      ":" +
                      params.row.working_time.split(":")[1]}
                  </>
                ),
              },
              {
                headerName: `${t("gangs")}`,
                field: "gangs",
                description: `${t("gangs")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.num_gangs}</>
                ),
              },
              {
                headerName: `${t("gmph")}`,
                field: "gmph",
                description: `${t("gmphDescr")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.gmph}</>
                ),
              },
              {
                headerName: `${t("gmph_gangs")}`,
                field: "gmph_gangs",
                description: `${t("gmph_gangs")}`,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <>{params.row.gmph_num_gangs}</>
                ),
              },
            ]}
            rows={dashboard}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardManager;
