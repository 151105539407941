import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { customLocaleText, lightColor } from "./utils";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

type CustomTableProps = {
  columns: GridColDef[];
  rows: any[];
  dense?: boolean;
  onRowClick?: (row: any) => void;
  checkbox?: {
    visible: boolean;
    selectedRows: any[];
    setSelectedRows: (rows: any[]) => void;
  };
  stickyHeader?: boolean;
  fixedHeight?: number;
};

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  rows,
  dense,
  onRowClick,
  checkbox,
  stickyHeader = false,
  fixedHeight = 600,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      style={{ height: stickyHeader ? fixedHeight : undefined, width: "100%" }}
    >
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            position: stickyHeader ? "sticky" : undefined,
            top: stickyHeader ? 0 : undefined,
            zIndex: stickyHeader ? 1 : undefined,
            backgroundColor: lightColor,
            lineHeight: "normal !important",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
            fontSize: "1.1rem",
            color: "#ffffff",
            whiteSpace: "normal",
            wordBreak: "break-word",
          },
          ".MuiDataGrid-columnHeader:hover .MuiDataGrid-columnHeaderTitle": {
            wordBreak: "normal !important",
            textOverflow: "ellipsis !important",
          },
          ".MuiDataGrid-columnHeader": {
            backgroundColor: lightColor,
            border: "none",
            minWidth: isMobile ? "250px !important" : undefined,
            maxWidth: isMobile ? "250px !important" : undefined,
          },
          ".MuiDataGrid-row": {
            cursor: onRowClick ? "pointer" : undefined,
          },
          ".MuiDataGrid-overlayWrapper": {
            height: "50px",
          },
          ".MuiDataGrid-cell": {
            minWidth: isMobile ? "250px !important" : undefined,
            maxWidth: isMobile ? "250px !important" : undefined,
            whiteSpace: "break-spaces !important",
            wordBreak: "break-word !important",
          },
        }}
        getRowHeight={() => "auto"}
        getRowId={(row) => rows.indexOf(row)}
        density={dense ? "compact" : "standard"}
        rows={rows}
        columns={columns}
        checkboxSelection={checkbox && checkbox.visible ? true : false}
        rowSelection={checkbox && checkbox.visible ? true : false}
        onRowSelectionModelChange={
          checkbox && checkbox.visible
            ? (newSelection) => checkbox.setSelectedRows(newSelection)
            : undefined
        }
        onRowClick={onRowClick ? (params) => onRowClick(params.row) : undefined}
        localeText={customLocaleText(t)}
        isCellEditable={() => false}
        slots={{
          noRowsOverlay: () => (
            <div style={{ textAlign: "center", paddingTop: "12px" }}>
              {t("noData")}
            </div>
          ),
        }}
      />
    </div>
  );
};

export default CustomTable;
