   import {
    methodGet,
    methodPost,
    verticalFetch,
    validateMessageFetch
  } from "./httpRequests";

  import {
    getRawMessagesUrl,
    getRawMessagUrl,
    validateMessageUrl,
    resendRawMessageUrl
  } from "./url";
  
  export const getAllRawMessages = () => {
    return verticalFetch(methodGet, getRawMessagesUrl);
  };

  export const getRawMessage = (id: string) => {
    return verticalFetch(methodGet, getRawMessagUrl(id));
  }

  export const validateMessage = (body: string) => {
    return validateMessageFetch(methodPost, validateMessageUrl, body);
  }

  export const resendRawMessage = (id: string, body: string) => {
    return validateMessageFetch(methodPost, resendRawMessageUrl(id), body);
  }